<template>
  <div class="d-flex justify-content-center">
    <div>
      <b-card class="d-flex text-center" v-if="state !== 'loading'">
        <h3>Daily Stock Captured</h3>
        <line-chart class="chart" :chartData="dataCollection.datasets" :labels="dataCollection.labels"
                    :options="options"></line-chart>
        <b-button variant="outline-primary" class="mt-3" @click="openStockModal">Edit</b-button>
      </b-card>
    </div>
    <b-modal id="stockViewModal" hide-footer size="xl" title="Stock Captured" @close="closeStockModal">
      <div v-if="!isStockSelected">
        <div class="d-flex w-100 mb-4">
          <div class="w-100 h-auto">
            <div class="d-flex justify-content-end ">
<!--              <b-button variant="outline-danger" class="mr-2" @click="clearStockSearch">Clear</b-button>
              <b-button variant="outline-primary" class="mr-2">Search</b-button>-->
            </div>
          </div>
        </div>
        <div>
          <b-table striped hover
                   :items="stockTableData.dataSource"
                   :fields="stockTableData.tableColumns"
                   :busy="stockTableData.isLoading"
                   @row-clicked="stockModalToggle"
                   :per-page="stockTableData.resultsPerPage"
                   id="stockTable"
                   sort-by="date"
                   sort-desc
                   :current-page="stockTableData.currentPage">

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button size="sm" class="btn-icon" @click="stockModalToggle(row.item)">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </b-button>
              </b-row>
            </template>

            <template #cell(date)="row">
              <b-row align-v="center">
                <span class="mr-auto">{{ row.item.date | dateTimeFilter }}</span>
              </b-row>
            </template>

          </b-table>
        </div>
        <div class="d-flex justify-content-center">
          <b-pagination
              v-model="stockTableData.currentPage"
              :total-rows="rows"
              :per-page="stockTableData.resultsPerPage"
              aria-controls="stockTable"
          ></b-pagination>
        </div>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="outline-danger" class="mr-2" @click="closeStockModal">Back</b-button>
        </div>
      </div>
      <div v-if="isStockSelected">
        <label>Stock Edit</label>

        <div class="d-flex justify-content-end">
          <b-button variant="outline-danger" class="mr-2" @click="stockModalToggle">Cancel</b-button>
          <b-button variant="danger" class="mr-2" @click="deleteStockItem">Delete</b-button>
        </div>

        <b-row class="mt-3">
          <b-col cols="4">
            <label>Harvest Front</label>
            <b-form-input disabled v-model="stockDetails.frontName"></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>Farm</label>
            <b-form-input disabled v-model="stockDetails.farmName"></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>Stock Amount</label>
            <b-form-input v-model="stockDetails.stock"></b-form-input>
          </b-col>
        </b-row>
        <div class="w-100 d-flex justify-content-end mt-3">
          <b-button variant="primary" class="mr-2" @click="UpdateStockLevel">Save</b-button>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import {bus} from '../../main'
import LineChart from "@/components/chart/lineChart";
import {mapActions} from "vuex";

export default {
  name: "graphStockView",
  components: {
    LineChart
  },
  props: ['stock'],
  data: () => ({
    state: 'loading',
    dataCollection: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Daily Stock",
          borderColor: "#3e95cd",
          fill: true,

          legend: {
            display: false
          },

        },
      ],

    },

    stockTableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: false,
      tableColumns: [
        {
          label: 'Date',
          key: 'date',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Front',
          key: 'fkFarm.fkFront.frontName',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Farm',
          key: 'fkFarm.farmName',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Stock',
          key: 'stock',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    stockSearchField: {
      stock: null,
    },
    stockDetails: {
      stockId: null,
      frontName: null,
      frontId: null,
      farmName: null,
      farmId: null,
      stock: null,
    },
    isStockSelected: false,
    options: {
      legend: {
        display: false
      },
      scales: {
        y: {
          beginAtZero: true
        }
      }
    },
  }),
  mounted () {
   // renderChart(this.datasets, this.options)
  },
  created() {
    // eslint-disable-next-line no-undef
    Chart.defaults.global.legend.display = false;

    // eslint-disable-next-line no-undef
    Chart.defaults.scale.ticks.min = 0;

    this.stockSearchResultForTable()
    bus.$on('changeIt', () => {
      this.stockSearchResultForTable()
    })

    //
  },
  methods: {
    ...mapActions(['getStockRequest', 'updateStockLevelRequest', 'deleteStock']),
    stockSearchResultForTable() {
      this.state = 'loading'
      this.getStockRequest()
          .then((response) => {
            let stockData = response.data
            this.dataCollection.labels = []
            let dataset = []
            stockData.forEach(element => {
              this.dataCollection.labels.push(element.fkFarm.farmName)
              dataset.push(element.stock)
            });
            this.dataCollection.datasets = [
              {
                label: 'Stock',
                backgroundColor: "#69BE6B",
                borderColor: "#69BE6B",
                borderWidth: 1,
                data: dataset
              }
            ]

            this.state = 'show'
            console.log('DATA', this.dataCollection)
          })
    },
    openStockModal() {
      this.$bvModal.show('stockViewModal')
      this.stockSearchResult()
      this.isStockSelected = false
    },
    closeStockModal() {
      this.stockSearchResultForTable()
      this.$bvModal.hide('stockViewModal')
    },

    clearStockSearch() {
      this.stockSearchField.stock = null
    },

    stockSearchResult() {
      this.stockTableData.isLoading = true

      this.getStockRequest()
          .then((response) => {
            this.stockTableData.dataSource = response.data
            this.stockTableData.isLoading = false
          })
    },

    stockModalToggle(rowItem) {
      this.isStockSelected = !this.isStockSelected
      this.stockDetails.stockId = rowItem.pkStockId
      this.stockDetails.frontName = rowItem.fkFarm.fkFront.frontName
      this.stockDetails.frontId = rowItem.fkFarm.fkFrontId
      this.stockDetails.farmName = rowItem.fkFarm.farmName
      this.stockDetails.farmId = rowItem.fkFarmId
      this.stockDetails.stock = rowItem.stock
    },

    UpdateStockLevel() {
      const stockUpdate = {}
      stockUpdate.farmId = this.stockDetails.farmId
      stockUpdate.stockAmount = parseInt(this.stockDetails.stock)
      stockUpdate.Id = this.stockDetails.stockId
      this.$store.commit('setUpdateStockRequest', stockUpdate)
      this.updateStockLevelRequest()
      .then(() => {
        this.closeStockModal()
      })
    },

    deleteStockItem() {
      this.$store.commit("setRemoveStockRequest", this.stockDetails.stockId)
      this.deleteStock()
          .then(() => {
            this.isStockSelected = false
            this.stockSearchResult()
          })
    },
  },
  computed: {
    rows() {
      return this.stockTableData.dataSource.length
    },
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>