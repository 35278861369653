<template>
  <div>
    <mq-layout mq="mobile">
      <div class="mt-5 pt-5">
        <h1>Please access this on a larger screen</h1>
      </div>
    </mq-layout>
    <mq-layout mq="tablet+">
      <b-row>
        <b-col cols="4">
          <label>Loader</label>
          <b-form-select v-model="loaderDetails" @change="changedLoader">
            <b-form-select-option v-for="(item, index) in loaderData" :key="index" :value="item">{{ item.loader1 }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="4" class="text-center" v-if="loadingGeneralData === false">
          <div v-if="loaderDetails.pkLoaderId" class="d-flex h-auto align-items-center">
            <h3><label class="mb-0 mr-2 align-bottom-custom">Harvest: 
              
              <span
                  class="font-weight-bold">{{loaderDetails.activity.fkStatusId === 2 ? loaderDetails.frontName : 'N/A'}}
              </span>
            </label> &nbsp;
            </h3>
          </div>
        </b-col>

        <b-col cols="4">
          <div v-if="loaderDetails.pkLoaderId" class="d-flex h-auto align-items-center">
            <h3>
              <label class="mb-0 mr-2 align-bottom-custom">Farm: 
                <span class="font-weight-bold">
                  {{ loaderDetails.fkFarm !== null && loaderDetails.fkFarm !== undefined && this.loaderDetails.activity.fkStatusId === 2? loaderDetails.fkFarm.farmName : 'N/A' }}
                </span>
              </label>
            </h3>
          </div>
        </b-col>
      </b-row>
      <div v-if="loaderDetails.pkLoaderId">

        <hr class="mx-3">
        <div class="d-flex justify-content-around mt-2">
          <b-button variant="outline-primary" class="buttonSize box" @click="setLoaderState(1)"
                    :class="this.loaderDetails.activity.fkStatusId === 1 ? 'active' : ''">Moving
          </b-button>
          <b-button :disabled="!loaderDetails.farmName" variant="outline-primary" class="buttonSize box"
                    :class="this.loaderDetails.activity.fkStatusId === 2 ? 'active' : ''"
                    @click="openFarmSearchModal"

          >Active
          </b-button>
          <b-button variant="outline-danger" class="buttonSize box" @click="setLoaderState(3)"
                    :class="this.loaderDetails.activity.fkStatusId === 3 ? 'active' : ''"
          >Breakdown
          </b-button>
        </div>
        <hr class="mx-3 my-3">
        <div v-if="loadingVechData" class="text-center">
          <b-spinner></b-spinner>
        </div>
        <div v-if="!loadingVechData">
          <div v-if="allocatedVehicleData.length > 0 && loaderDetails.activity.fkStatusId === 2">
            <div class="mt-5 d-flex justify-content-around flex-wrap">
              <div class="m-3"
                  v-for="vehicle in allocatedVehicleData"
                  :key="vehicle.index">
                <div @click="openStatusModal(vehicle)"

                   class="status buttonStyle icon"
                    :class="vehicle.fkVehicle.fkVehicleStatus !== null ? vehicle.fkVehicle.fkVehicleStatus.vehicleStatus1 : ''">{{ vehicle.fkVehicleId }}
                    <!-- class="status buttonStyle icon" >{{ vehicle.fkVehicleId }} -->
                <br><span class="font">{{ vehicle.fkVehicle.fkVehicleStatus.vehicleStatus1 }}</span>
                </div>
              </div>
            </div>
            <hr class="mx-3 my-3">
          </div>
        </div>
        
      </div>
    </mq-layout>
    <b-modal id="FarmSearchModal" hide-footer size="xl" title="Farm Search" @close="closeFarmSearchModal">
    
      <div>
        <div>
          <b-table striped hover
                   :items="farmTableData.dataSource"
                   :fields="farmTableData.tableColumns"
                   :busy="farmTableData.isLoading"
                   @row-clicked="addFarm"
                   :per-page="farmTableData.resultsPerPage"
                   id="farmTable"
                   :current-page="farmTableData.currentPage">

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button @click="addFarm(row.item)" size="sm" class="btn-icon">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </b-button>
              </b-row>
            </template>

          </b-table>
        </div>
        <div class="d-flex justify-content-center">
          <b-pagination
              v-model="farmTableData.currentPage"
              :total-rows="rows"
              :per-page="farmTableData.resultsPerPage"
              aria-controls="farmTable"
          ></b-pagination>
        </div>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="outline-danger" class="mr-2" @click="closeFarmSearchModal">Cancel</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="vehicleStatusModal" hide-footer size="xl" title="Update Vehicle Status" @close="closeStatusModal">
      <h3>You are about to Update Vehicle {{ selectedVehicle }}</h3>
      <b-form-select class="w-25 mt-3" v-model="vehicleStatusDetails.statusId">
        <b-form-select-option v-for="(item, index) in statusUpdate" :key="index" :value="item.Id">{{ item.status }}
        </b-form-select-option>
      </b-form-select>
      <div class="d-flex justify-content-end w-100">
        <b-button variant="outline-danger" class="mr-2" @click="closeStatusModal">Cancel</b-button>
        <b-button variant="outline-primary" class="mr-2" @click="updateVehicleStatus">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  //TODO refresh components on update
  name: "loader",
  data: () => ({
    loaderData: [],
    loadingVechData: false,
    allocatedVehicleData: [],
    loadingGeneralData: false,
    filteredAllocatedVehicleData: [],
    loaderSelected: null,
    farmsSearchRequest: [],
    farmTableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: false,
      tableColumns: [
        {
          label: 'Farm',
          key: 'fkFarm.farmName',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    fronts: [],
    statusUpdate: [
      {Id: 2, status: 'OutBound'},
      {Id: 4, status: 'Loading'},
      {Id: 5, status: 'Inbound'},
    ],
    frontsFilter: [],
    isActive: true,
    frontFilter: [],
    loaderDetails: {
      loader: [],
      activity: {fkStatusId: 0},
      FKLoaderId: null,
      fKfarmId: null,
      farmName: null,
      statusId: null,
    },
    vehicleStatusDetails: {
      vehicleId: null,
      statusId: null
    },
    isLoaderActive: false,
    isLoaderBreakdown: false,
    selectedVehicle: null,
    componentKeyOutbound: 0,
    componentKeyNotOutbound: 0,
  }),
  created() {
    this.frontSearchResult()
    this.loaderSearchResult()
    // this.getAllocatedVehicles()
  },
  updated() {

  },
  methods: {
    ...mapActions(['getFrontsRequest', 'getFarmScheduleRequest', 'getLoaderRequest', 'getSentVehicleRequest',
      "getVehicleStatusRequest", "updateVehicleStatusRequest", "updateLoaderStatusRequest"]),

    closeFarmSearchModal() {
      this.$bvModal.hide('FarmSearchModal')
    },
    changedLoader() {
      if (!Array.prototype.last) {
        Array.prototype.last = function () {
          return this[this.length - 1];
        };
      }
      this.loadingGeneralData = true

      this.getLoaderRequest()
      .then((response) => {
        this.loaderData = response.data
        this.loaderDetails.FKLoaderId = this.loaderDetails.pkLoaderId;

        let selectedLoader = this.loaderData.find(element => element.pkLoaderId === this.loaderDetails.FKLoaderId)


        console.log('loader set heresadjk')
        this.loaderDetails = selectedLoader

        var activity = this.loaderDetails.loaderActivities.last();

        this.loaderDetails.farmName = activity.fkFarm === null ? 'N/A' : activity.fkFarm.farmName;
        this.loaderDetails.frontName = activity.fkFarm === null ? 'N/A' : activity.fkFarm.fkFront.frontName;


        console.log('loader set here')
        this.loaderDetails.activity = activity
        //console.log(activity)
        
      this.loadingGeneralData = false

        this.getAllocatedVehicles();
      })
      
    },
    openFarmSearchModal() {
      this.$bvModal.show('FarmSearchModal')
      this.farmsSearchResult()
    },
    clearFrontHarvestSearch() {
      this.frontHarvestSearch.front = null

    },

    addFarm(rowItem) {
      this.loaderDetails.fkFarmId = rowItem.fkFarmId
      this.loaderDetails.fkFarm = rowItem.fkFarm
      this.setLoaderState(2)
      //console.log('oidsfspdfij')
      this.FrontFilterRequest()
      this.closeFarmSearchModal()
      this.getAllocatedVehicles()
    },

    frontSearchResult() {
      this.getFrontsRequest()
      .then((request) => {
        this.fronts = request.data
      })
    },
    FrontFilterRequest() {
      //console.log('fronts', this.fronts)
      this.frontFilter = this.fronts.filter(item => item.pkFrontId === this.loaderDetails.fkFarm.fkFrontId)
      this.loaderDetails.frontName = this.frontFilter[0].frontName
    },
    farmsSearchResult() {
      this.farmTableData.isLoading = true
      this.getFarmScheduleRequest()
      .then((request) => {
        this.farmTableData.isLoading = false
        this.farmTableData.dataSource = request.data
      })
    },
    loaderSearchResult() {
      this.getLoaderRequest()
      .then((response) => {
        //console.log('loader info', response.data)
        this.loaderData = response.data
        this.state = 'show'
      })
    },

    openStatusModal(vehicle) {
      this.$bvModal.show('vehicleStatusModal')
      this.selectedVehicle = vehicle.fkVehicle.pkVehicleId
    },
    closeStatusModal() {
      this.$bvModal.hide('vehicleStatusModal')

    },

    getAllocatedVehicles() {
      console.log('get allocated vehicles')
      this.loadingVechData = true
      this.getSentVehicleRequest()
          .then((request) => {
            var statusesBuckets = {};

            //console.log('apples', this.loaderDetails)

            this.allocatedVehicleData =   request.data.filter(item => {
              if (item.fkSentToFarm === null)
                return false;
              if (this.loaderDetails.fkFarm !== null) {
                return item.fkSentToFarm.farmName === this.loaderDetails.fkFarm.farmName;
              }
            })

            // only get this farms data
            request.data.filter(item => {
              if (item.fkSentToFarm === null)
                return false;
              if (this.loaderDetails.fkFarm !== null) {
                return item.fkSentToFarm.farmName === this.loaderDetails.fkFarm.farmName;
              }
            })
                //now devide them by status into buckets
                .forEach(x => {
                  if (!(x.fkVehicle.fkVehicleStatusId in statusesBuckets)) {
                    statusesBuckets[x.fkVehicle.fkVehicleStatusId] = [];
                  }

                  statusesBuckets[x.fkVehicle.fkVehicleStatusId].push(x);
                });

            //console.log('statusesBuckets', statusesBuckets)

            var newStatusesBuckets = [];
            //sort the individual buckets
            Object.entries(statusesBuckets).forEach(([key, value]) => {
              //console.log(key, value);
              if (parseInt(key.toString()) === 5) {
                console.log("Key is 5")
                console.log("value", value)
                newStatusesBuckets[key] = value.sort(
                    (a, b) => {
                      console.log("a", a)
                     /* var date_a = new Date(a.modifiedDate);
                      var date_b = new Date(b.modifiedDate);
                      console.log("date_a", date_a)
                      console.log("date_b", date_b)
                      return date_a - date_b;*/
                      console.log("a.FkVehicle.modifiedDateTicks", a.fkVehicle.modifiedDateTicks)
                      return  a.fkVehicle.modifiedDateTicks - b.fkVehicle.modifiedDateTicks;
                    });


                newStatusesBuckets[key] = [ newStatusesBuckets[key].last() ];
              /*  newStatusesBuckets[key] =  statusesBuckets[key];*/
              } else {
                newStatusesBuckets[key] = value.sort(
                    (a, b) => {
                      return a.fkVehicleId - b.fkVehicleId;
                    });
              }

            });

            //rebuild the buckets into an array
            var finalData = [];
            Object.entries(newStatusesBuckets).forEach(([key]) => {
              newStatusesBuckets[key].forEach(x => finalData.push(x));
            });

            //console.log("finalData", finalData)
            this.allocatedVehicleData = finalData;

            this.loadingVechData = false

          })
    }
    ,

    updateVehicleStatus() {
      const vehicleStatusChanged = {}
      vehicleStatusChanged.vehicleId = this.selectedVehicle
      vehicleStatusChanged.VehicleStatusId = this.vehicleStatusDetails.statusId
      this.$store.commit('setUpdateVehicleStatus', vehicleStatusChanged)
      this.updateVehicleStatusRequest()
      .then(() => {
        this.getAllocatedVehicles()
        this.closeStatusModal()
      })
    },
    setLoaderState(state) {
      // this.loaderDetails.activity.fkStatusId = state
      let request = {
        fkLoaderId: this.loaderDetails.pkLoaderId,
        fkStatusId: state,
        fkFarmId: this.loaderDetails.fkFarmId
      }
      this.$store.commit('setUpdateLoaderStatus', request)
      this.state = 'loading'
      this.updateLoaderStatusRequest()
      .then(() => {
        this.loaderDetails.activity.fkStatusId = state
      })
    }
  },
  computed: {
    rows() {
      return this.farmTableData.dataSource.length
    },
    IsActive() {
      return this.allocatedVehicleData.filter(item => item.fkVehicle.fkVehicleStatus.vehicleStatus1 !== 'OutBound').length > 0;
    }
  },
}
</script>

<style scoped>
.buttonSize {
  height: 12rem;
  width: 12rem;
  font-size: 2rem;
  border-radius: 12px;
}

/*.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
}*/

.align-bottom-custom {
  position: absolute;
  bottom: 0px;
}

.status.Inbound {
  display: block;
  border: solid 1px green;
  background-color: green;
  color: #ffffff;
  padding: 3rem;
  flex-flow: wrap;
  position: relative;
}

.status.Loading {
  display: block;
  background-color: yellow;
  padding: 3rem;
  flex-flow: wrap;
  position: relative;
}

.status.Outbound {
  display: block;
  background-color: #007bff;
  color: white;
  padding: 3rem;
  flex-flow: wrap;
  position: relative;
}

.status {
  display: block;
  border: solid 1px #000000;
  background-color: yellow;
  padding: 3rem;
  flex-flow: wrap;
  position: relative;
}

.status.OutBound {
  display: block;
  border: solid 1px black;
  background-color: #2d2828;
  color: #ffffff;
  padding: 3rem;
  flex-flow: wrap;
  position: relative;
}

.status.Breakdown {
  display: block;
  border: solid 1px #f80404;
  background-color: #f80404;
  color: #ffffff;
  padding: 3rem;
  flex-flow: wrap;
  position: relative;
}

.font {
  font-size: 1rem;
  position: absolute;
}

.buttonStyle {
  font-size: 2rem;
  border-radius: 12px;
}
</style>