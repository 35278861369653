<template>
  <!--TODO make smaller components of the reused searches-->
  <div>
    <h3 class="text-center">Today's Stock</h3>
    <label>Harvest Front</label>
    <b-input-group>
      <!--            <b-form-input disabled v-model="stockDetails.frontName"></b-form-input>-->
      <b-form-select v-model="stockDetails.frontName" class="mb-3" @change="addFront(stockDetails.frontName)">
        <b-form-select-option :value="null">Please select an option</b-form-select-option>
        <b-form-select-option :value="front.frontName" v-for="front in frontTableData.dataSource" :key="front.pkFarmId">
          {{ front.frontName }}
        </b-form-select-option>
      </b-form-select>
      <!--            <b-input-group-append>
                      <b-button size="sm" text="Button" variant="outline-secondary" @click="openFrontSearchModal">Search</b-button>
                  </b-input-group-append>-->
    </b-input-group>
    <label>Farm</label>
    <b-form-select v-model="stockDetails.pkFarmId">
      <b-form-select-option v-for="(item, index) in farms" :key="index" :value="item.pkFarmId">{{ item.farmName }}
      </b-form-select-option>
    </b-form-select>
    <label>Stock</label>
    <b-form-input v-model="stockDetails.stock"></b-form-input>
    <div class="d-flex justify-content-center">
      <b-button variant="outline-danger" class="mt-3" @click="clearStock">Cancel</b-button>
      <b-button variant="outline-primary" class="mt-3 ml-3" @click="createStockItem">Save</b-button>
    </div>

    <b-modal id="FrontSearchModal" hide-footer size="xl" title="Harvest Front Search" @close="closeFrontSearchModal">
      <label>Harvest Search</label>
      <div class="d-flex w-100 mb-4">
        <div class="w-50 h-auto">
          <b-form-input v-model="frontHarvestSearch.front" @keyup="filterFronts"></b-form-input>
        </div>
        <div class="w-50 h-auto">
          <div class="d-flex justify-content-end ">
            <b-button variant="outline-danger" class="mr-2" @click="clearFrontHarvestSearch">Clear</b-button>
            <b-button variant="outline-primary" class="mr-2" @click="filterFronts">Search</b-button>
          </div>
        </div>
      </div>
      <div>
        <b-table striped hover
                 :items="frontTableData.dataSource"
                 :fields="frontTableData.tableColumns"
                 :busy="frontTableData.isLoading"
                 @row-clicked="addFront"
                 :per-page="frontTableData.resultsPerPage"
                 id="stockTable"
                 :current-page="frontTableData.currentPage">

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button size="sm" class="btn-icon" @click="addFront(row.item)">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>

        </b-table>
      </div>
      <div class="d-flex justify-content-center">
        <b-pagination
            v-model="frontTableData.currentPage"
            :total-rows="rows"
            :per-page="frontTableData.resultsPerPage"
            aria-controls="stockTable"
        ></b-pagination>
      </div>
      <div class="d-flex justify-content-end w-100">
        <b-button variant="outline-danger" class="mr-2" @click="closeFrontSearchModal">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {bus} from '../../main'
import {mapActions} from "vuex";
import api from "../../api.js"

export default {
  name: "stockFormView",

  data: () => ({
    frontTableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: false,
      tableColumns: [
        {
          label: 'Front',
          key: 'frontName',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    frontHarvestSearch: {
      front: null,
    },
    farmsSearchRequest: [],
    frontsOriginal: [],
    farmsOriginal: [],
    farms: [],
    stockDetails: {
      frontName: null,
      farmName: null,
      pkFarmId: null,
      stock: null,
    },
  }),
  created() {
    this.loadFarms();
    this.loadFronts();

  },
  methods: {
    ...mapActions(['getFrontsRequest', 'getFarmsRequest', 'createStock']),

    loadFarms() {
      var s = (data) => {
        this.farms = data;
        this.farmsOriginal = data;
      };

      var e = (error) => {
        alert(error);
      };

      api.farmsGetAll(s, e);
    },
    loadFronts() {
      var s = (data) => {
        this.frontTableData.dataSource = data;
        this.frontsOriginal = data;
        this.frontTableData.isLoading = false;
      };

      var e = (error) => {
        alert(error);
      };

      api.frontGetAll(s, e);
    },
    filterFronts() {
      this.frontTableData.dataSource = this.frontsOriginal.filter(item => item.frontName.toLowerCase().includes(this.frontHarvestSearch.front.toLowerCase()));
    },
    closeFrontSearchModal() {
      this.$bvModal.hide('FrontSearchModal')
    },
    openFrontSearchModal() {
      this.$bvModal.show('FrontSearchModal')
    },

    clearFrontHarvestSearch() {
      this.frontHarvestSearch.front = null
      this.loadFronts()
    },
    addFront(rowItem) {
      console.log("rowItem", rowItem)
      /*this.closeFrontSearchModal()*/
      this.stockDetails.frontName = rowItem
      this.farmFilter()
    },
    farmFilter() {
      this.farms = this.farmsOriginal.filter(item => item.fkFront.frontName === this.stockDetails.frontName);
    },

    createStockItem() {
      const stock = {}
      stock.fkFarmId = this.stockDetails.pkFarmId
      stock.stock = parseInt(this.stockDetails.stock)
      stock.Deleted = false
      this.$store.commit('setCreateStockRequest', stock)
      this.createStock()
          .then(() => {
            bus.$emit('changeIt')
          })
      this.clearStock()

    },
    clearStock() {
      this.stockDetails.frontName = null
      this.stockDetails.pkFarmId = null
      this.stockDetails.stock = null
    },

  },
  computed: {
    rows() {
      return this.frontTableData.dataSource.length
    }
  }

}
</script>

<style scoped>

</style>