import axios from "axios";

export default {
    //
    // [FARM]
    //
    farmsGetAll(s,e) {
        axios({
            method: "get",
            url: localStorage.getItem('apiUrl') + "Farm/getAll"
        })
            .then(function (response) {
                s(response.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Vehicle");
            });
    },

    //
    // [Front]
    //
    frontGetAll(s,e) {
        axios({
            method: "get",
            url: localStorage.getItem('apiUrl') + "Front/getall"
        })
            .then(function (response) {
                s(response.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Vehicle");
            });
    },

    //
    // [Vehicle]
    //
    vehiclesGetAll(s, e) {
        axios({
            method: "get",
            url: localStorage.getItem('apiUrl') + "Vehicle/getall"
        })
            .then(function (response) {
                s(response.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Vehicle");
            });
    },
    vehiclesSave(vehicle,s, e) {
        axios({
            method: "post",
            url: localStorage.getItem('apiUrl') + "Vehicle/Save",
            data: vehicle
        })
            .then(function (response) {
                s(response.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Vehicle");
            });
    },
    vehiclesGetAllSent(s, e) {
        axios({
            method: "get",
            url: localStorage.getItem('apiUrl') + "Vehicle/getAllSent"
        })
            .then(function (response) {
                s(response.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Vehicle");
            });
    },
    vehiclesGetAllVehicleStatus(s, e) {
        axios({
            method: "get",
            url: localStorage.getItem('apiUrl') + "Vehicle/getAllVehicleStatus"
        })
            .then(function (response) {
                s(response.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Vehicle");
            });
    },
    vehiclesSaveUpdateStatus(vehicle, s, e) {
        axios({
            method: "post",
            url: localStorage.getItem('apiUrl') + "Vehicle/SaveUpdateStatus",
            data: vehicle
        })
            .then(function (response) {
                s(response.data);
            })
            .catch(function (error) {
                console.error(error);
                e("Failed to load Vehicle");
            });
    }
}