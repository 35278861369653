<template>
    <div>
        <b-row class="mt-3">
            <b-col>
                <b-card>
                    <div class="d-flex justify-content-end">
                        <b-button variant="outline-primary" class="mr-2" @click="takeBackToSearch">Cancel</b-button>
                    </div>
                    <b-row class="mt-3">
                        <b-table striped hover
                                 :items="vehicleSchedulerData.dataSource"
                                 :fields="vehicleSchedulerData.tableColumns"
                                 :busy="vehicleSchedulerData.isLoading"
                                 @row-clicked="openStatusModal"
                                 :per-page="vehicleSchedulerData.resultsPerPage"
                                 id="vehicleTable"
                                 :current-page="vehicleSchedulerData.currentPage">

                            <template #table-busy>
                                <div class="text-center my-2">
                                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                </div>
                            </template>

                            <template #cell(actions)="row">
                                <b-row align-v="center" align-h="end">
                                    <b-button size="sm" class="btn-icon" @click="openStatusModal(row.item)">
                                        <b-icon-chevron-right></b-icon-chevron-right>
                                    </b-button>
                                    
                                </b-row>
                            </template>

                        </b-table>
                    </b-row>
                    <b-row class="d-flex justify-content-center">
                        <b-pagination
                            v-model="vehicleSchedulerData.currentPage"
                            :total-rows="rows"
                            :per-page="vehicleSchedulerData.resultsPerPage"
                            aria-controls="vehicleTable"
                        ></b-pagination>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-modal id="vehicleStatusUpdate" hide-footer size="xl" title="Vehicle Status update" @close="closeStatusModal">
            <div class="mt-3">

                <b-row class="mt-3">
                    <b-col class="pl-0" cols="6">
                        <label>Vehicle</label>
                        <b-form-input disabled v-model="vehicleDetails.vehicleId"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label>Status</label>
                        <b-form-select v-model="vehicleDetails.statusId">
                            <b-form-select-option v-for="(item, index) in status" :key="index"
                                                  :value="item.vehicleStatusId">{{ item.vehicleStatus1 }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <div class="d-flex justify-content-end">
                    <div>
                        <b-button variant="outline-danger" class="mr-2" @click="closeStatusModal">Cancel</b-button>
                        <b-button variant="primary" @click="updatedVehicleStatuses">Save</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "stockEditView",
    data: () => ({
        status: [],
        vehicleSchedulerData: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: false,
            tableColumns: [
                {
                    label: 'Vehicle  ID',
                    key: 'pkVehicleId',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Status',
                    key: 'fkVehicleStatus.vehicleStatus1',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        vehicleDetails: {
            vehicleId: null,
            statusId: null,
        },
    }),
    created() {
        this.vehicleSearchResult();

        // this.vehicleSearchResult()
    },
    methods: {
        ...mapActions(['getVehicleRequest', 'getVehicleStatusRequest', 'updateVehicleStatusRequest']),
        
        takeBackToSearch() {
            this.$router.back()
        },
        closeStatusModal() {
            this.$bvModal.hide('vehicleStatusUpdate')
        },
        openStatusModal(rowItem) {
            this.$bvModal.show('vehicleStatusUpdate')
            this.vehicleDetails.vehicleId = rowItem.pkVehicleId
            this.vehicleStatuses()
        },
        vehicleSearchResult() {
            this.vehicleSchedulerData.isLoading = true
            
            const request = []
            this.$store.commit('setVehicleRequest', request)
            this.getVehicleRequest()
            .then((response) => {
                this.vehicleSchedulerData.isLoading = false
                this.vehicleSchedulerData.dataSource = response.data
            })
        },
        vehicleStatuses() {
            this.getVehicleStatusRequest()
                .then((response) => {
                    this.status = response.data
                })
        },
        updatedVehicleStatuses() {
            const vehicleUpdateStatusPayload = {}
            vehicleUpdateStatusPayload.VehicleId = this.vehicleDetails.vehicleId
            vehicleUpdateStatusPayload.VehicleStatusId = this.vehicleDetails.statusId
            this.$store.commit('setUpdateVehicleStatus', vehicleUpdateStatusPayload)
            this.updateVehicleStatusRequest()
            .then(() => {
                this.closeStatusModal()
                this.vehicleSearchResult()
            }) 
        },
    },
    computed: {
        rows() {
            return this.vehicleSchedulerData.dataSource.length
        },
    },
}
</script>

<style scoped>

</style>