import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        baseUrl: window.localStorage.getItem('apiUrl'),
        
        //selected
        selectedStock: null,
        
        
        //request initial state
        
        farmsRequest: null,
        
        vehicleRequest: null,
        createVehicleRequest: null,
        updateVehicleStatus: null,
        vehicleStatusFilteredRequest: null,
        
        loaderRequest: null,
        updateLoaderStatus: null,
        
        createStockRequest: null,
        updateStockRequest: null,
        removeStockRequest: null,
    },
    mutations: {
        //set selected
        setStockSelected: (state, payload) => {state.selectedStock = payload},
        
        
        setApiUrl: (state, payload) => {
            state.baseUrl = payload
            localStorage.setItem('apiUrl', payload)
        },

        
        setFarmsRequest: (state, payload) => {state.farmsRequest = payload},
        
        setVehicleRequest: (state, payload) => {state.vehicelRequest = payload},
        setCreateVehicleRequest: (state, payload) => {state.createVehicleRequest = payload},
        setUpdateVehicleStatus: (state, payload) => {state.updateVehicleStatus = payload},
        setVehicleStatusFilteredRequest: (state, payload) => {state.vehicleStatusFilteredRequest = payload},
        
        setLoaderRequest: (state, payload) => {state.loaderRequest = payload},
        setUpdateLoaderStatus: (state, payload) => {state.updateLoaderStatus = payload},
        
        setCreateStockRequest: (state, payload) => {state.createStockRequest = payload},
        setUpdateStockRequest: (state, payload) => {state.updateStockRequest = payload},
        setRemoveStockRequest: (state, payload) => {state.removeStockRequest = payload},
    },
    actions: {
        
        //Fronts
        getFrontsRequest: ({dispatch}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Front/getall',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        dispatch({error:  err.toString(), message:'There was an issue'})
                        reject(err)
                    })
            })
        },

        //Farms
        getFarmsRequest: ({dispatch}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Farm/getAll',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        dispatch({error:  err.toString(), message:'There was an issue'})
                        reject(err)
                    })
            })
        },
        getFarmScheduleRequest: ({dispatch}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Farm/GetFarmSchedule',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        dispatch({error:  err.toString(), message:'There was an issue'})
                        reject(err)
                    })
            })
        },
        
        
        //Vehicles
        getVehicleRequest: ({state}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Vehicle/getall',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        state.vehicleRequest = response.data
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        getVehicleSchedulerTableData: ({state}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Vehicle/GetVehicleSchedulerTableData',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        state.vehicleRequest = response.data
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createVehicleSchedule: ({state}) => {
            let payload = state.createVehicleRequest
            

            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'post',
                    url: localStorage.getItem('apiUrl') + 'Vehicle/save',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: payload
                };
                axios(callConfig)
                    .then(response => {
                        resolve(response)

                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
        getSentVehicleRequest: ({state}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Vehicle/getAllSent',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        state.vehicleRequest = response.data
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getVehicleStatusRequest: () => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Vehicle/getAllVehicleStatus',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateVehicleStatusRequest: ({state}) => {
            let payload = state.updateVehicleStatus
            
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'post',
                    url: localStorage.getItem('apiUrl') + 'Vehicle/UpdatedVehicleStatus',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: payload
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getFilteredVehicleRequest: () => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Vehicle/GetAllFilteredByStatus',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        
        
        //Loaders
        getLoaderRequest: ({state, dispatch}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Loader/getall',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        state.loaderRequest = response.data
                        resolve(response)
                    })
                    .catch(err => {
                        dispatch({error:  err.toString(), message:'There was an issue'})
                        reject(err)
                    })
            })
        },
        updateLoaderStatusRequest: ({state}) => {
            let payload = state.updateLoaderStatus

            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'post',
                    url: localStorage.getItem('apiUrl') + 'Loader/UpdatedLoaderStatus',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: payload
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        
        
        //Stock
        getStockRequest: ({dispatch}) => {
            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'get',
                    url: localStorage.getItem('apiUrl') + 'Stock/getAll',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                    },
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        dispatch({error:  err.toString(), message:'There was an issue'})
                        reject(err)
                    })
            })
        },
        createStock: ({state}) => {
            let payload = state.createStockRequest

            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'post',
                    url: localStorage.getItem('apiUrl') + 'Stock/save',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: payload
                };
                axios(callConfig)
                    .then(response => {
                        resolve(response)

                    })
                    .catch(err => {
                        // dispatch({error:  err.toString(), message:'There was an issue saving the stock'})
                        reject(err)
                    })

            })
        },
        updateStockLevelRequest: ({state}) => {
            let payload = state.updateStockRequest

            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'post',
                    url: localStorage.getItem('apiUrl') + 'Stock/UpdateStock',
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: payload
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteStock: ({state}) => {
            let payload = state.removeStockRequest
            
            console.log("STORE", payload)

            return new Promise((resolve, reject) =>{
                const callConfig = {
                    method: 'post',
                    url: localStorage.getItem('apiUrl') + 'Stock/DeleteStock/' + payload,
                    headers: {
                        // 'Authorization': 'Bearer '+ localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: payload
                };

                axios(callConfig)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    },
    modules: {},
    getters: {},
})