<template>
  <div>
    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-right">
                <router-link to="loader" >
                  <b-button style="height: 100%;" size="sm" text="Button" variant="outline-primary">Loaders</b-button>
                </router-link></b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- <b-input-group-append> -->
                <b-form-input v-model="searchTerm" @keyup="filter" ></b-form-input>
              <!-- </b-input-group-append> -->
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-table striped hover
                     sort-icon-left
                     :items="vehicleSchedulerData.dataSource"
                     :fields="vehicleSchedulerData.tableColumns"
                     :busy="vehicleSchedulerData.isLoading"
                     @row-clicked="openStatusModal"
                     :per-page="vehicleSchedulerData.resultsPerPage"
                     id="vehicleTable"
                     :current-page="vehicleSchedulerData.currentPage">

              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                </div>
              </template>
              <template #cell(modifiedDate)="row">
                {{row.item.modifiedDate | dateTimeFilter}}
              </template>

              <template #cell(statusId)="row">
                {{row.item.statusId }} <span v-if="row.item.statusId === 'Delayed'">({{row.item.delayedETA | dateTimeFilter}})</span>
              </template>
              <template #cell(actions)="row">
                <b-row align-v="center" align-h="end">
                  <b-button v-if="row.item.statusId !== 'Outbound'" size="sm" class="btn-icon" @click="openStatusModal(row.item)">
                    <b-icon-chevron-right></b-icon-chevron-right>
                  </b-button>

                </b-row>
              </template>

            </b-table>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <b-pagination
                v-model="vehicleSchedulerData.currentPage"
                :total-rows="rows"
                :per-page="vehicleSchedulerData.resultsPerPage"
                aria-controls="vehicleTable"
            ></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="vehicleStatusUpdate" hide-footer size="xl" title="Vehicle Status update" @close="closeStatusModal">
      <b-row v-if="showError">
        <b-col class="pl-0">
          <b-alert variant="warning" show>{{errorMessage}}</b-alert>
        </b-col>
      </b-row>
      <div class="mt-3">
        <b-row class="mt-3">
          <b-col class="pl-0" cols="6">
            <label>Vehicle</label>
            <b-form-input disabled v-model="vehicleDetails.vehicleName"></b-form-input>
          </b-col>
          <b-col cols="6" class="pl-0">
            <label>Status</label>
            <b-form-select v-model="vehicleDetails.statusId">
              <b-form-select-option v-for="(item, index) in status" :key="index"
                                    :value="item.vehicleStatusId">{{ item.vehicleStatus1 }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        
        <b-row class="mt-3" v-if="vehicleDetails.statusId === 10">
          <b-col class="pl-0" cols="6">
            <label>Enter New Arrival Date</label>
              <b-form-datepicker v-model="vehicleDetails.etaDate" :min="minDate" :max="maxDate"></b-form-datepicker>
          </b-col>
          <b-col class="pl-0" cols="6">
            <label>Enter New Arrival Time</label>
            <b-form-timepicker v-model="vehicleDetails.etaTime"
            ></b-form-timepicker>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col class="d-flex justify-content-end">
            <b-button variant="outline-danger" class="mr-2" @click="closeStatusModal">Cancel</b-button>
            <b-button variant="primary" @click="updatedVehicleStatuses">Save</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {bus} from "../../main";

export default {
  name: "vehicleScheduler",
  data: () => ({
    searchTerm: "",
    selectedVehicle: null,
    status: [],
    mod: {
      fromDate: null,
      toDate: null
    },
    minDate: new Date(),
    maxDate: null,
    showError: false,
    errorMessage: null,
    vehicleSchedulerData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSourceOrigonal: [],
      dataSource: [],
      isLoading: false,
      tableColumns: [
        {
          label: 'Vehicle',
          key: 'vehicleName',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Haulier',
          key: 'vehicleOwner',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Front',
          key: 'front',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Farm',
          key: 'farm',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Status',
          key: 'statusId',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Last Updated',
          key: 'modifiedDate',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    vehicleDetails: {
      vehicleId: null,
      vehicleName: null,
      statusId: null,
      etaTime: null,
      etaDate: null
    },
    ogList: []
  }),
  created() {
    this.maxDate = new Date()
    
    this.maxDate.setDate(this.maxDate.getDate() + 1);

    this.vehicleSearchResult();
    bus.$on('vechicleUpdate', () => {
      this.vehicleSearchResult()
    })

    setTimeout(() => {
      this.vehicleSearchResult();
    }, 60_000)

    // this.vehicleSearchResult()
  },
  methods: {
    ...mapActions(['getVehicleRequest', 'getVehicleStatusRequest', 'updateVehicleStatusRequest', 'getFarmsRequest', 'getVehicleSchedulerTableData']),
    filter() {
      this.vehicleSchedulerData.dataSource = this.vehicleSchedulerData.dataSourceOrigonal.filter(x => {
        let foundValue = false

        if (x.delayedETA !== null) {
          let eta = new Date(x.delayedETA)
          let etaString = eta.getDate().toString().padStart(2, '0') + '/'+ (eta.getMonth()+1).toString().padStart(2, '0') + '/' + eta.getFullYear().toString()
          if (etaString.includes(this.searchTerm.toLowerCase())) {
            foundValue = true
          }
        }

        if (x.modifiedDate !== null) {
          let mod = new Date(x.modifiedDate)
          let modString = mod.getDate().toString().padStart(2, '0') + '/'+ (mod.getMonth()+1).toString().padStart(2, '0') + '/' + mod.getFullYear().toString()
          if (modString.includes(this.searchTerm.toLowerCase())) {
            foundValue = true
          }
        }

        let searchField = JSON.stringify(x).toLowerCase()
        let replacedString = searchField.replace('delayedeta', 'eta')
        if (replacedString.includes(this.searchTerm.toLowerCase())) {
          foundValue = true
        } 

        return foundValue
      });
    },
    takeBackToSearch() {
      this.$router.back()
    },
    closeStatusModal() {
      this.$bvModal.hide('vehicleStatusUpdate')
      console.log("Emit updateDisplay")
      bus.$emit('updateDisplay');
    },
    openStatusModal(rowItem) {
      this.showError = false
      this.errorMessage = null
      this.$bvModal.show('vehicleStatusUpdate')
      let date = new Date()
      this.vehicleDetails.etaDate = date
      this.vehicleDetails.etaTime = date.getHours() + ':' + date.getMinutes() 
      this.vehicleDetails.vehicleId = rowItem.vehicleId
      this.vehicleDetails.vehicleName = rowItem.vehicleName
      this.selectedVehicle = rowItem
      this.vehicleStatuses()


    },
    vehicleSearchResult() {
      this.vehicleSchedulerData.isLoading = true

      const request = []
      this.$store.commit('setVehicleRequest', request)
      this.getVehicleSchedulerTableData()
      .then((response) => {
        this.vehicleSchedulerData.isLoading = false
        this.vehicleSchedulerData.dataSource = response.data
        this.vehicleSchedulerData.dataSourceOrigonal = response.data

        this.filter()
      })



    },
    vehicleStatuses() {
      this.getVehicleStatusRequest()
          .then((response) => {
            this.status = response.data
          })
    },
    updatedVehicleStatuses() {
      if (this.vehicleDetails.statusId === 3 && this.selectedVehicle.farmName === null) {
        return
      }
      let eta = null

      if (this.vehicleDetails.statusId === 10) {
        if (this.vehicleDetails.etaDate === null) {
          this.vehicleDetails.etaDate = new Date()
        }
        
        let date = new Date(this.vehicleDetails.etaDate)

        let hour = this.vehicleDetails.etaTime.substr(0,2)
        let minute = this.vehicleDetails.etaTime.substr(3,2)

        date.setHours(hour)
        date.setMinutes(minute)
        eta = date

      }
      
        let nowDate = new Date()

        if (eta !== null && eta < nowDate) {
          this.showError = true
          this.errorMessage = 'Date and time selected cannot be in the past'
          return
        }
      
      const vehicleUpdateStatusPayload = {}
      vehicleUpdateStatusPayload.VehicleId = this.vehicleDetails.vehicleId
      vehicleUpdateStatusPayload.VehicleStatusId = this.vehicleDetails.statusId
      vehicleUpdateStatusPayload.delayETA = eta
      this.$store.commit('setUpdateVehicleStatus', vehicleUpdateStatusPayload)
      this.updateVehicleStatusRequest()
      .then(() => {
        this.vehicleSearchResult()
        this.closeStatusModal()
      })
    },
  },
  computed: {
    rows() {
      return this.vehicleSchedulerData.dataSource.length
    },
  },
}
</script>

<style scoped>

</style>