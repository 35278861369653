<template>
    <div class="mt-3">
        <b-card>
            <div class="d-flex justify-content-between">
                <h3>Stock</h3>
                <div>
                    <b-button variant="outline-danger" class="mr-2" @click="takeBackToSearch">Cancel</b-button>
                    <b-button variant="danger" class="mr-2" >Delete</b-button>
                </div>
            </div>
            
            <b-row class="mt-3">
                <b-col cols="4">
                    <label>Harvest Front</label>
                    <b-form-input disabled></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Farm</label>
                    <b-form-input disabled></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Stock Amount</label>
                    <b-form-input></b-form-input>
                </b-col>
            </b-row>
            <div class="w-100 d-flex justify-content-end mt-3">
                <b-button variant="primary" class="mr-2" >Save</b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
export default {
    name: "stockEditView",
    props: {
        // rowItem: 
    },
    data: () => ({}),
    created() {},
    methods: {
        takeBackToSearch() {
            this.$router.back()
        },
    },
    computed() {},
}
</script>

<style scoped>

</style>