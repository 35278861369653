import layout from '../views/layout/layout'
import main from '../views/main/main'
import stockEditView from "@/views/stockEditView/stockEditView";
import vehicleStatusView from "@/views/vehicleStatusView/vehicleStatusView";
import loader from "@/views/loader/loader";
import login from "@/views/login/login";

export default [
    {
        path: '/login',
        name: 'login',
        component: login,
    },
    {
        path: "/", 
        component: layout, 
        children: [
            {
                path: '/',
                name: 'main',
                component: main,
            },
            {
                path: '/stock-edit',
                name: 'stockEditView',
                component: stockEditView,
            },
            {
                path: '/vehicle-status',
                name: 'vehicleStatusView',
                component: vehicleStatusView,
            },
            {
                path: '/loader',
                name: 'loader',
                component: loader,
            },
        ]
    },
    
]