<template>
    <div >
        <mq-layout mq="laptop+">
            <div class="d-flex mt-4 justify-content-lg-around mx-2">
                <div class="w-75">
                    <b-card class="d-flex">
                        <b-tabs v-model="tabIndex" align="center">
                            <b-tab title="Stock Capture">
                                <graphView />
                            </b-tab>
                            <b-tab title="Vehicle Scheduler">
                                <vehicleScheduler />
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
                <div class="w-25 ml-2">
                    <b-card class="d-flex">
                        <div v-if="tabIndex === 0">
                            <stockFormView />
                        </div>
                        <div v-if="tabIndex === 1">
                            <vehicleFormView />
                        </div>
                    </b-card>
                </div>
            </div>
        </mq-layout>
       
        <mq-layout mq="tablet">
            <div class="d-flex mt-4 flex-column ">
                <div class="w-100">
                    <b-card class="d-flex">
                        <b-tabs v-model="tabIndex" align="center">
                            <b-tab title="Stock Capture">
                                <graphView />
                            </b-tab>
                            <b-tab title="Vehicle Scheduler">
                                <vehicleScheduler />
                            </b-tab>
                        </b-tabs>

                    </b-card>
                </div>
                <div class="w-100 mt-4">
                    <b-card class="d-flex">
                        <div v-if="tabIndex === 0">
                            <stockFormView />
                        </div>
                        <div v-if="tabIndex === 1">
                            <vehicleFormView />
                        </div>
                    </b-card>
                </div>
            </div>
        </mq-layout>
    </div>
</template>

<script>
import graphView from "@/components/stockCapture/graphStockView";
import vehicleScheduler from "@/components/vehicleScheduler/vehicleScheduler";
import stockFormView from "@/components/stockCapture/stockFormView";
import vehicleFormView from "@/components/vehicleScheduler/vehicleFormView";

export default {
    name: 'Home',
    components:{
        graphView,
        vehicleScheduler,
        stockFormView,
        vehicleFormView,
    },
    data: () => ({
        tabIndex: 0,
    }),
    methods: {},
    created() {},
    computed: {}
};
</script>

<style scoped>
</style>

