<template>
    <div >
        <mq-layout mq="tablet">
            <div class="d-flex flex-column">
                <div class="w-50 mx-auto">
                    <stockForm />
                </div>
            </div>
        </mq-layout>
        <mq-layout mq="laptop+">
            <div class="d-flex flex-column">
                <div class="w-100 mx-auto">
                    <stockForm />
                </div>
            </div>
        </mq-layout>
    </div>
</template>

<script>
import stockForm from "@/components/smallComponents/stockForm";
export default {
    name: "stockFormView",
    components: {
        stockForm
    },
}
</script>

<style scoped>

</style>