<template>
  <div>
    <mq-layout mq="tablet">
      <div class="w-50 mx-auto">
        <vehicleForm/>
      </div>
    </mq-layout>
    <mq-layout mq="laptop+">
      <div class="w-100 mx-auto">
        <vehicleForm/>
      </div>
    </mq-layout>
  </div>
</template>

<script>
import vehicleForm from "@/components/smallComponents/vehicleForm";
export default {
  name: "vehicleFormView",
  components: {
    vehicleForm,
  },

}
</script>

<style scoped>

</style>