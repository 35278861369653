import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: {
        chartData : Array,
        labels: Array
    } ,
    data: function () {
        return {
            datacollection: {
                labels: this.labels,
                datasets: this.chartData
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                    mode: 'single'
                },
                responsive: true,
                maintainAspectRatio: false,
                height: 2000
            }
        }
    },
    mounted () {
        this.renderChart(this.datacollection)
    }
}

