<template>
  <div>
    <h3 class="text-center">Vehicle Scheduler</h3>
    <label>Vehicle</label>
<!--    <b-input-group>
      <b-form-input disabled v-model="vehicleDetails.fkVehicleId"></b-form-input>
      <b-input-group-append>
        <b-button size="sm" text="Button" variant="outline-secondary" @click="openVehicleSearchModal">Search</b-button>
      </b-input-group-append>
    </b-input-group>-->

    <b-form-select v-model="vehicleDetails.fkVehicleId" class="mb-3" >
      <b-form-select-option :value="null">Please select an option</b-form-select-option>
      <b-form-select-option :value="vehicle.pkVehicleId" v-for="vehicle in vehicleTableData.dataSource" :key="vehicle.pkVehicleId">
        {{ vehicle.vehicleName }}
      </b-form-select-option>
    </b-form-select>

    <label>Farm Schedule</label>
<!--
    <b-input-group>
      <b-form-input disabled v-model="vehicleDetails.farmName"></b-form-input>
      <b-input-group-append>
        <b-button size="sm" text="Button" variant="outline-secondary" @click="openFrontSearchModal">Search</b-button>
      </b-input-group-append>
    </b-input-group>
-->

    <b-form-select v-model="vehicleDetails.pkFarmId" class="mb-3" >
      <b-form-select-option :value="null">Please select an option</b-form-select-option>
      <b-form-select-option :value="farm.fkFarmId" v-for="farm in farmTableData.dataSource" :key="farm.fkFarmId">
        {{ farm.fkFarm.farmName }}
      </b-form-select-option>
    </b-form-select>


    <div class="d-flex justify-content-center">
        <b-button variant="outline-danger" class="mt-3" @click="clearVehicle">Clear</b-button>
        <b-button variant="primary" class="mt-3 ml-3" @click="vehicleSchedule">Save</b-button>
        <!--            <b-button hidden variant="outline-primary" class="mt-3 ml-3" @click="redirectToPage">Status</b-button>-->
    </div>

    <b-modal id="FrontSearchModal" hide-footer size="xl" title="Harvest Front Search" @close="closeFrontSearchModal">
      <div>
        <b-table striped hover
                 :items="farmTableData.dataSource"
                 :fields="farmTableData.tableColumns"
                 :busy="farmTableData.isLoading"
                 @row-clicked="addFarm"
                 :per-page="farmTableData.resultsPerPage"
                 id="vehicleTable"
                 :current-page="farmTableData.currentPage">

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button size="sm" class="btn-icon" @click="addFarm(row.item)">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>

        </b-table>
      </div>
      <div class="d-flex justify-content-center">
        <b-pagination
            v-model="farmTableData.currentPage"
            :total-rows="rows"
            :per-page="farmTableData.resultsPerPage"
            aria-controls="vehicleTable"
        ></b-pagination>
      </div>
      <div class="d-flex justify-content-end w-100">
        <b-button variant="outline-danger" class="mr-2" @click="closeFrontSearchModal">Cancel</b-button>
      </div>
    </b-modal>

    <b-modal id="VehicleSearchModal" hide-footer size="xl" title="Vehicle Search" @close="closeVehicleSearchModal">
      <label>Vehicle Search</label>
      <div class="d-flex w-100 mb-4">
        <div class="w-50 h-auto">
          <b-form-input v-model="vehicleSearchField.vehicle"></b-form-input>
        </div>
        <div class="w-50 h-auto">
          <div class="d-flex justify-content-end ">
            <b-button variant="outline-danger" class="mr-2" @click="clearVehicleSearchField">Clear</b-button>
            <b-button variant="outline-primary" class="mr-2" @click="filterVehicle()">Search</b-button>
          </div>
        </div>
      </div>
      <div>
        <b-table striped hover
                 :items="vehicleTableData.dataSource"
                 :fields="vehicleTableData.tableColumns"
                 :busy="vehicleTableData.isLoading"
                 @row-clicked="addVehicle"
                 :per-page="vehicleTableData.resultsPerPage"
                 id="stockTable"
                 :current-page="vehicleTableData.currentPage">

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button size="sm" class="btn-icon" @click="addVehicle(row.item)">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>

        </b-table>
      </div>
      <div class="d-flex justify-content-center">
        <b-pagination
            v-model="vehicleTableData.currentPage"
            :total-rows="vehicleRows"
            :per-page="vehicleTableData.resultsPerPage"
            aria-controls="stockTable"
        ></b-pagination>
      </div>
      <div class="d-flex justify-content-end w-100">
        <b-button variant="outline-danger" class="mr-2" @click="closeVehicleSearchModal">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {bus} from '../../main'
import {mapActions} from "vuex";

export default {
  name: "vehicleFormView",
  data: () => ({
    farmTableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: false,
      tableColumns: [
        {
          label: 'Farm',
          key: 'fkFarm.farmName',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    frontsOriginal: [],
    frontHarvestSearch: {
      front: null,
    },
    vehicleSearchField: {
      vehicle: null
    },
    vehicleTableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: false,
      tableColumns: [
        {
          label: 'Vehicle',
          key: 'pkVehicleId',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    farmsSearchRequest: [],
    // farms: [],
    vehicleDetails: {
      frontName: null,
      pkFarmId: null,
      farmName: null,
      fkVehicleId: null,
      status: null,
    },
    vehicleOriginal: [],
  }),
  created() {
    bus.$on('updateDisplay', () => {
      this.farmSearchResult()
      this.vehicleSearchResult()
    })
    this.farmSearchResult()
    this.vehicleSearchResult()
  },
  methods: {
    ...mapActions(['getFilteredVehicleRequest', "createVehicleSchedule", 'getFarmScheduleRequest']),

    closeFrontSearchModal() {
      this.$bvModal.hide('FrontSearchModal')
    },
    openFrontSearchModal() {
      this.$bvModal.show('FrontSearchModal')
    },
    // clearFrontHarvestSearch() {
    //     this.frontHarvestSearch.front = null
    //     // this.frontSearchResult()
    // },

    openVehicleSearchModal() {
      this.$bvModal.show('VehicleSearchModal')
    },
    closeVehicleSearchModal() {
      this.$bvModal.hide('VehicleSearchModal')
    },
    filterFronts() {
      this.frontTableData.dataSource = this.frontsOriginal.filter(item => item.frontName.toLowerCase().includes(this.frontHarvestSearch.front.toLowerCase()));
    },
    clearVehicleSearchField() {
      this.vehicleSearchField.vehicle = null
      this.vehicleSearchResult()
    },

    redirectToPage() {
      this.$router.push({path: '/vehicle-status'})
    },

    farmSearchResult() {
      this.farmTableData.isLoading = true

      let request = []

      this.$store.commit("setFarmsScheduleRequest", request)
      this.getFarmScheduleRequest()
          .then((request) => {
            console.log("this.farmTableData.dataSource", this.farmTableData.dataSource)
            this.farmTableData.isLoading = false
            this.farmTableData.dataSource = request.data
            this.farmsOriginal = request.data
          })
    },
    addFarm(rowItem) {
      this.vehicleDetails.pkFarmId = rowItem.fkFarm.pkFarmId
      this.vehicleDetails.farmName = rowItem.fkFarm.farmName
      this.closeFrontSearchModal()
    },
    vehicleSearchResult() {
      this.vehicleTableData.isLoading = true

      this.getFilteredVehicleRequest()
          .then((response) => {
            this.vehicleTableData.dataSource = response.data
            this.vehicleOriginal = response.data
            this.vehicleTableData.isLoading = false
          })
    },
    filterVehicle() {
      this.vehicleTableData.dataSource = this.vehicleOriginal.filter(item => item.pkVehicleId.toString().includes(this.vehicleSearchField.vehicle))

    },
    addVehicle(rowItem) {
      this.vehicleDetails.fkVehicleId = rowItem.pkVehicleId
      this.closeVehicleSearchModal()
    },

    vehicleSchedule() {
      if(this.vehicleDetails.farmName === null && this.vehicleDetails.fkVehicleId === null)
        return;

      const vehicle = {}
      vehicle.fkSentToFarmId = this.vehicleDetails.pkFarmId
      vehicle.farmName = this.vehicleDetails.farmName
      vehicle.fkVehicleId = this.vehicleDetails.fkVehicleId

      this.$store.commit('setCreateVehicleRequest', vehicle)
      this.createVehicleSchedule()
      .then(() => {
        bus.$emit('vechicleUpdate')
      })
      .catch(() => {
        this.vehicleTableData.isLoading = false
      })
      this.clearVehicle()
    },

    clearVehicle() {
      this.vehicleDetails.farmName = null
      this.vehicleDetails.fkVehicleId = null
      this.vehicleSearchResult()
    },
  },
  computed: {
    rows() {
      return this.farmTableData.dataSource.length
    },
    vehicleRows() {
      return this.vehicleTableData.dataSource.length
    },
  },
}
</script>

<style scoped>

</style>