<template>
    <div class="layout">
        <img src="./RCl-Logo.png" alt="RCL Logo" class="logo">
        <router-view ></router-view>
    </div>
</template>

<script>


import { mapMutations} from "vuex";

export default {
    ...mapMutations(['setApiUrl']),
    components: {},
    methods: {},
    beforeCreate() {
        // this.$store.commit('setRootData', this.$root.urlConfig)
    },
    created() {
        this.$store.commit('setApiUrl', this.$root.urlConfig.api_url)
    },
    computed: {},
}
</script>

<style scoped>

</style>