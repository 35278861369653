<template>
    <div>
        <layout />
        <div class="loginScreen d-flex justify-content-center align-items-center">
            <b-card class="w-25">
                <h6 class="font-weight-bold text-center"> Welcome, Please enter your details below</h6>
                <hr class="mx-3" />
                <label>User Name:</label>
                <b-form-input />
                <label>Password:</label>
                <b-form-input />
                <div class="d-flex justify-content-center">
                    <b-button variant="outline-primary" class="mt-3">Log In</b-button>
                </div>
                
            </b-card>
        </div>
    </div>
    
</template>

<script>
import Layout from "@/views/layout/layout";
export default {
    name: "login",
    components: {Layout}
}
</script>

<style scoped>
    .loginScreen {
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
</style>